import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import {
  Control,
  FieldValues,
  FormState,
  useForm,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import {
  CamposCorrigidos,
  CapaData,
  DadosErpDoFornecedor,
  ParamentrosValidacao,
  ProductDataToTable,
  SelectType,
  // XmlProps,
} from './protocols';

import { schema } from './validations/parametrosValidation';
import clearValidation from './validations/clearValidation.json';
import { checkInvalidItensTable } from './validations/checkInvalidItensTable';
import { checkInvalidCapa } from './validations/checkInvalidCapa';
import { toast } from 'react-toastify';

type FormType = {
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  control: Control<FieldValues, any>;
  reset: UseFormReset<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  formState: FormState<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
};

type ValidadorXMLContextData = {
  dadosDaCapa: CapaData;
  setDadosDaCapa: React.Dispatch<React.SetStateAction<CapaData>>;
  showXMLData: string;
  setShowXMLData: React.Dispatch<React.SetStateAction<string>>;
  showParams: string;
  setShowParams: React.Dispatch<React.SetStateAction<string>>;
  showCapa: string;
  setShowCapa: React.Dispatch<React.SetStateAction<string>>;
  formParamsValidacao: FormType;
  setParametrosValidacao: React.Dispatch<
    React.SetStateAction<ParamentrosValidacao>
  >;
  parametrosValidacao: ParamentrosValidacao;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  perfis: SelectType[];
  setPerfis: React.Dispatch<React.SetStateAction<SelectType[]>>;
  isValidXML: boolean;
  setIsValidXML: React.Dispatch<React.SetStateAction<boolean>>;
  showTotais: string;
  setShowTotais: React.Dispatch<React.SetStateAction<string>>;
  showTable: string;
  setShowTable: React.Dispatch<React.SetStateAction<string>>;
  dadosDoXML: any | undefined;
  setDadosDoXML: React.Dispatch<React.SetStateAction<any | undefined>>;
  dadosErpDoFornecedor: DadosErpDoFornecedor;
  setDadosErpDoFornecedor: React.Dispatch<
    React.SetStateAction<DadosErpDoFornecedor>
  >;
  itensDaTabela: ProductDataToTable[];
  setItensDaTabela: React.Dispatch<React.SetStateAction<ProductDataToTable[]>>;
  key: string;
  setKey: React.Dispatch<React.SetStateAction<string>>;
  showContextMenu: boolean;
  setShowContextMenu: React.Dispatch<React.SetStateAction<boolean>>;
  callableFunction: string;
  setCallableFunction: React.Dispatch<React.SetStateAction<string>>;
  itemACorrigir: ProductDataToTable;
  setItemACorrigir: React.Dispatch<React.SetStateAction<ProductDataToTable>>;
  showModalDeCorrecoes: boolean;
  setShowModalDeCorrecoes: React.Dispatch<React.SetStateAction<boolean>>;
  camposAlterados: CamposCorrigidos[];
  setCamposAlterados: React.Dispatch<React.SetStateAction<CamposCorrigidos[]>>;
  returnDadosRevalidados: (itemRevalidado: ProductDataToTable) => void;
  statusValidacao: string[];
  setStatusValidacao: React.Dispatch<React.SetStateAction<string[]>>;
  anchorPoint: {
    x: number;
    y: number;
  };
  setAnchorPoint: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
    }>
  >;
  resetFormData: () => void;
  isUpdate: boolean;
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  showSearch: boolean;
  setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
  hasFornecedor: boolean;
  setHasFornecedor: React.Dispatch<React.SetStateAction<boolean>>;
  tipoDoXML: SelectType[];
  setTipoDoXML: React.Dispatch<React.SetStateAction<SelectType[]>>;
  onSetItemACorrigir: (item: ProductDataToTable) => void;
  isItensTableInvalid: boolean;
  onSetToastShown: (val: boolean) => void;
  onSetCounterValida: (val?: number) => void;
  setPdf: React.Dispatch<any>;
  pdf: any;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenModal: boolean;
  setLoadingPDF: React.Dispatch<React.SetStateAction<boolean>>;
  loadingPDF: boolean;
};

export const ValidadorXMLContext = createContext({} as ValidadorXMLContextData);

interface ValidadorXMLContextProviderProps {
  children: ReactNode;
}

export function ValidadorXMLContextProvider({
  children,
}: ValidadorXMLContextProviderProps): JSX.Element {
  // const [loader, setLoader] = useState<boolean>(false);
  const [hasFornecedor, setHasFornecedor] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [showModalDeCorrecoes, setShowModalDeCorrecoes] =
    useState<boolean>(false);
  // const [showSearch, setShowSearch] = useState<boolean>(true);
  // const [lojas, setLojas] = useState<number | number[]>();
  const [perfis, setPerfis] = useState<SelectType[]>([]);
  const [dadosDaCapa, setDadosDaCapa] = useState<CapaData>({} as CapaData);
  const [dadosDoXML, setDadosDoXML] = useState<any>();
  const [dadosErpDoFornecedor, setDadosErpDoFornecedor] =
    useState<DadosErpDoFornecedor>({} as DadosErpDoFornecedor);
  const [itensDaTabela, setItensDaTabela] = useState<ProductDataToTable[]>([]);
  const [isItensTableInvalid, setIsItensTableInvalid] = useState(false);
  const [itemACorrigir, setItemACorrigir] = useState<ProductDataToTable>(
    {} as ProductDataToTable,
  );
  const [parametrosValidacao, setParametrosValidacao] =
    useState<ParamentrosValidacao>({} as ParamentrosValidacao);

  const [key, setKey] = useState<string>('divergencias');
  const [tipoDoXML, setTipoDoXML] = useState<SelectType[]>([
    {
      value: 0,
      label: 'Compra',
    },
    {
      value: 1,
      label: 'Outras',
    },
  ]);
  // const [modalTabKey, setModalTabKey] = useState<string>('lista');
  // const [validationStatus, setValidationStatus] = useState<string[]>([]);
  const [isValidXML, setIsValidXML] = useState<boolean>(false);
  const [camposAlterados, setCamposAlterados] = useState<CamposCorrigidos[]>(
    [],
  );

  // contextmenu position
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [callableFunction, setCallableFunction] = useState<string>('');

  // use 0 para aberto e 1 para fechado
  const [showXMLData, setShowXMLData] = useState<string>('0');
  const [showParams, setShowParams] = useState<string>('0');
  const [showTable, setShowTable] = useState<string>('1');
  const [showTotais, setShowTotais] = useState<string>('1');
  const [showCapa, setShowCapa] = useState<string>('1');
  const [toastShown, setToastShown] = useState(false);
  const [counterValida, setCounterValida] = useState(0);

  // Status da Validação
  const [statusValidacao, setStatusValidacao] = useState<string[]>([]);

  const [pdf, setPdf] = useState<any>(undefined);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);

  // Chave ICMS

  /**
   * Parâmetros de Validação
   */
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  /**
   * Funções
   */

  const onSetItemACorrigir = useCallback((item: ProductDataToTable) => {
    setItemACorrigir(item);
  }, []);

  const onSetToastShown = useCallback((val: boolean) => {
    setToastShown(val);
  }, []);

  const onSetCounterValida = useCallback((val?: number) => {
    if (val) setCounterValida(val);
    else setCounterValida((prev) => prev + 1);
  }, []);

  const returnDadosRevalidados = useCallback(
    (itemRevalidado: ProductDataToTable) => {
      const itensExcetoRevalidado = itensDaTabela.filter(
        (item) => item.index !== itemRevalidado.index,
      );
      const temp = [...itensExcetoRevalidado, itemRevalidado];

      onSetItemACorrigir(itemRevalidado);
      setItensDaTabela(temp);
    },
    [itensDaTabela, onSetItemACorrigir],
  );

  const resetFormData = () => {
    setShowSearch(true);
    setUpdate(false);
    setPerfis([]);
    setDadosDaCapa({} as CapaData);
    setDadosDoXML(undefined);
    setDadosErpDoFornecedor({} as DadosErpDoFornecedor);
    setItensDaTabela([]);
    setItemACorrigir({} as ProductDataToTable);
    onSetItemACorrigir({} as ProductDataToTable);
    setParametrosValidacao({} as ParamentrosValidacao);
    setKey('divergencias');
    setIsValidXML(false);
    setCamposAlterados([]);
    setAnchorPoint({ x: 0, y: 0 });
    setShowContextMenu(false);
    setCallableFunction('');
    setShowXMLData('0');
    setShowParams('0');
    setShowTable('1');
    setShowTotais('1');
    setStatusValidacao([]);
    reset(clearValidation);
    setCounterValida(0);
  };

  useEffect(() => {
    itensDaTabela.sort(function (a: ProductDataToTable, b: ProductDataToTable) {
      if (Number(a.index) < Number(b.index)) {
        return -1;
      }
      if (Number(a.index) > Number(b.index)) {
        return 1;
      }
      return 0;
    });
    setItensDaTabela(itensDaTabela);
    setIsItensTableInvalid(checkInvalidItensTable(itensDaTabela));
  }, [itensDaTabela]);

  useEffect(() => {
    const isCapaInvalid = checkInvalidCapa(dadosErpDoFornecedor);

    let invalidItens = false;

    itensDaTabela.forEach((item) => {
      if (invalidItens) return;

      if (typeof item.statusDaValidacao === 'string') {
        invalidItens = false;
      } else if (
        Array.isArray(item.statusDaValidacao) &&
        item.statusDaValidacao.length > 0
      ) {
        invalidItens = true;
      }
    });

    if (
      !toastShown &&
      !isCapaInvalid &&
      !invalidItens &&
      itensDaTabela.length > 0 &&
      counterValida > 0 &&
      parametrosValidacao.cod_perfil !== null
    ) {
      setToastShown(true);
      toast.success('XML validado sem divergências!');
    }
  }, [
    isItensTableInvalid,
    dadosErpDoFornecedor,
    toastShown,
    itensDaTabela,
    counterValida,
    parametrosValidacao.cod_perfil,
  ]);

  return (
    <ValidadorXMLContext.Provider
      value={{
        dadosDaCapa,
        setDadosDaCapa,
        showXMLData,
        setShowXMLData,
        showParams,
        setShowParams,
        showCapa,
        setShowCapa,
        parametrosValidacao,
        setParametrosValidacao,
        loading,
        setLoading,
        perfis,
        setPerfis,
        isValidXML,
        setIsValidXML,
        showTotais,
        setShowTotais,
        dadosDoXML,
        setDadosDoXML,
        dadosErpDoFornecedor,
        setDadosErpDoFornecedor,
        onSetCounterValida,
        itensDaTabela,
        setItensDaTabela,
        showTable,
        setShowTable,
        key,
        setKey,
        anchorPoint,
        setAnchorPoint,
        showContextMenu,
        setShowContextMenu,
        callableFunction,
        setCallableFunction,
        itemACorrigir,
        setItemACorrigir,
        showModalDeCorrecoes,
        setShowModalDeCorrecoes,
        camposAlterados,
        setCamposAlterados,
        returnDadosRevalidados,
        statusValidacao,
        setStatusValidacao,
        resetFormData,
        isUpdate,
        setUpdate,
        showSearch,
        setShowSearch,
        hasFornecedor,
        setHasFornecedor,
        tipoDoXML,
        setTipoDoXML,
        onSetItemACorrigir,
        isItensTableInvalid,
        onSetToastShown,
        formParamsValidacao: {
          register,
          handleSubmit,
          control,
          setValue,
          getValues,
          setError,
          clearErrors,
          formState,
          watch,
          reset,
        },
        isOpenModal,
        setIsOpenModal,
        pdf,
        setPdf,
        loadingPDF,
        setLoadingPDF,
      }}
    >
      {children}
    </ValidadorXMLContext.Provider>
  );
}

export const useValidadorXML = (): ValidadorXMLContextData => {
  return useContext(ValidadorXMLContext);
};
